<template>
  <div></div>
</template>

<script>
import firebase from "firebase";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["unbindCurrentUser"])
  },
  created() {
    this.unbindCurrentUser();
  },
  mounted() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        //localStorage.setItem('authenticated', false)

        window.location.href = "/login";
      });
  }
};
</script>

<style></style>
